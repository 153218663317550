import React from 'react';
import useContent from '../../content/useContent';
import Image from '../Image/Image';

const MainClients = () => {
  const content = useContent('mainClients');
  const { title, logoUrls } = content;

  return (
    <div className='main-clients'>
      <div className='main-clients__inside boxed'>
        <h4 className='main-clients__title text-center normal'>{title}</h4>
        <div className='main-clients__logos'>
          {logoUrls.map((imageUrl) => (
            <Image key={imageUrl} filename={imageUrl} alt='main-client-logo' />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainClients;
