import React, { useRef, useEffect } from 'react';
import { faTimes, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import useContent from '../../content/useContent';
import useOutsideClick from '../../hooks/useOutsideClick';
import Image from '../Image/Image';
import Button from '../Button/Button';
import { scrollToId } from '../../utils/scrollToId';

const UsageExampleModal = ({ forwardedRef, usageId, onClose, isModalVisible, getIcon }) => {
  const modalRef = useRef(null);
  const content = useContent('usageExamples');
  const { beforeTitle, afterTitle, modalTitle, usages, modalCloseText, modalRequestDemoText } = content;
  const currentUsage = usages[usageId];
  const { usageTitle, usageTags, usageText, beforeAfter } = currentUsage;

  const goToContact = () => {
    onClose();
    scrollToId('contact');
  };

  useOutsideClick(modalRef, onClose, isModalVisible);

  return (
    <div className='modal-wrapper'>
      <AnimatePresence>
        {isModalVisible && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className='modal modal-wrapper' ref={forwardedRef}>
              <div className='modal__inside' ref={modalRef}>
                <button type='button' className='modal__exit' onClick={onClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className='modal__head'>
                  <div className='usage-examples__top'>
                    <div className='usage-examples__icon'>{getIcon()}</div>
                  </div>
                </div>
                <div className='modal__content'>
                  <h4 className='f4 text-center'>{modalTitle}</h4>
                  <h2 className='w1 text-center'>{usageTitle}</h2>
                  <p className='text-center'>{usageText}</p>
                  <div className='modal__before-after-wrapper'>
                    <div className='modal__before-after'>
                      <h3 className='f5 w2 modal__before-title'>{beforeTitle}</h3>
                      <h3 className='f5 w2 modal__after-title'>{afterTitle}</h3>
                    </div>
                    {beforeAfter &&
                      beforeAfter.map(({ before, after, modalTags }, index) => (
                        <div key={index} className='modal__before-after'>
                          <div>{before}</div>
                          <div>
                            <div className='modal__after'>{after}</div>
                            <div className='tags'>
                              {modalTags.map((tag) => (
                                <h4 key={tag} className='tags__tag'>
                                  #{tag}
                                </h4>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className='modal__btns-wrapper'>
                    <Button
                      id='btnExamplesCloseModal'
                      text={modalCloseText}
                      icon={faTimes}
                      type={2}
                      isIconFirst
                      onClick={onClose}
                    />
                    <Button
                      id='btnExamplesGoToContact'
                      text={modalRequestDemoText}
                      icon={faCaretRight}
                      type={1}
                      onClick={goToContact}
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UsageExampleModal;
