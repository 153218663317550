import React, { useState, useRef, useEffect } from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import useContent from '../../content/useContent';
import Image from '../Image/Image';
import Button from '../Button/Button';
import { scrollToId } from '../../utils/scrollToId';

const variants = {
  left: { x: 0 },
  right: { x: '-100%' },
};

const ForWho = () => {
  const tabsRef = useRef(null);
  const content = useContent('forWho');
  const { title, tabs, button } = content;
  const { buttonText } = button;

  const [currentTab, setCurrentTab] = useState(0);

  const setTab = (index, name) => {
    window.dataLayer.push({
      event: 'features',
      feature: name,
    });

    setCurrentTab(index);
  };

  return (
    <section id='forWho' className='for-who section section--light'>
      <div className='for-who__inside boxed col-1-1'>
        <div className='for-who__img-wrapper'>
          <Image filename='chow-ilustration.png' />
        </div>
        <div className='content'>
          <div className='for-who__top'>
            <h2 className='for-who__title'>{title}</h2>
            <nav className='for-who__tabs'>
              {tabs.map(({ tabId, tabTitle }, index) => (
                <button
                  key={tabId}
                  type='button'
                  onClick={() => setTab(index, tabTitle)}
                  className={`for-who__tab ${currentTab === index ? 'active' : ''}`}
                >
                  {tabTitle}
                </button>
              ))}
            </nav>
          </div>
          <div className='for-who__essence-main'>
            <motion.div
              className='for-who__essence-wrapper'
              ref={tabsRef}
              animate={currentTab === 0 ? 'left' : 'right'}
              variants={variants}
              transition={{ duration: 0.9 }}
            >
              {tabs.map(({ tabId, tabContent }, index) => (
                <ul key={tabId} className='for-who__essence'>
                  {tabContent.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                  <Button
                    id='btnForWhoGoToContact'
                    text={buttonText}
                    icon={faCaretRight}
                    type={1}
                    onClick={() => scrollToId('contact')}
                  />
                </ul>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForWho;
