import React, {useEffect, useRef, useState} from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import useContent from '../../content/useContent';
import Button from '../Button/Button';
import Image from '../Image/Image';
import { scrollToId } from '../../utils/scrollToId';

const About = () => {
  const videoWrapperRef = useRef(null);

  const content = useContent('about');
  const { title, essence, btnText, youtubeVideoUrl } = content;
  const [url, setUrl] = useState()

  useEffect(() => {
      const generateYoutubeIframe = () => {
        const videoWrapper = videoWrapperRef.current;
        if (!videoWrapper) return;

        while (videoWrapper.firstChild) {
          videoWrapper.firstChild.remove();
        }

        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', youtubeVideoUrl);
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', '400');

        videoWrapper.appendChild(iframe);
      }

      generateYoutubeIframe();
  }, [youtubeVideoUrl])

  return (
    <section id='about' className='about section section--light'>
      <div className='about__inside boxed col-1-1'>
        <div ref={videoWrapperRef}>
          {/*<Image className='about__img' filename='screen-temp.png' />*/}
        </div>
        <div>
          <h2 className='section__title'>{title}</h2>
          <p className='about__essence'>{essence}</p>
          <div className='about__last-row'>
            <Button
              id='btnAboutGoToContact'
              text={btnText}
              icon={faCaretRight}
              type={2}
              onClick={() => scrollToId('contact')}
            />
            <div className='about__img-wrapper'>
              <Image className='about__img' filename='platforms.png' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
