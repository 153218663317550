import React, { useState, useRef, useEffect } from 'react';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scroller } from 'react-scroll';
import useContent from '../../content/useContent';
import useWindowSize from '../../hooks/useWindowSize';
import Image from '../Image/Image';
import useElementSize from '../../hooks/useElementSize';

const HowIsItWorking = () => {
  const stepsRef = useRef(null);
  const { aboveDesktop } = useWindowSize();
  const content = useContent('howIsItWorking');
  const { title, essence, steps } = content;

  const { windowWidth } = useWindowSize();

  const [stepsWidths, setStepsWidths] = useState(null);
  const [stepsPositions, setStepsPositions] = useState(null);

  useEffect(() => {
    if (!stepsRef.current) return;
    setStepsWidths([...stepsRef.current.children].map((child) => child.offsetWidth));
  }, [windowWidth]);

  useEffect(() => {
    if (!stepsWidths) return;

    setStepsPositions([
      0,
      stepsWidths[0] + 30,
      stepsWidths[0] + stepsWidths[1] + 30,
      stepsWidths[0] + stepsWidths[1] + stepsWidths[2] + 30,
    ]);
  }, [stepsWidths]);

  const [currentStep, setCurrentStep] = useState(steps[0].id);

  const getClassForStep = (step) => {
    if (currentStep === step) return 'active';
    return '';
  };

  const nextStep = () => {
    if (currentStep + 1 > steps.length) return;

    stepsRef.current.scrollLeft = stepsPositions[currentStep];
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    if (currentStep <= 1) return;

    stepsRef.current.scrollLeft = stepsPositions[currentStep - 2];
    setCurrentStep((prev) => prev - 1);
  };

  const selectStep = (stepId) => {
    window.dataLayer.push({
      event: 'features',
      feature: stepId,
    });
    setCurrentStep(stepId);
  };

  return (
    <section id='howIsItWorking' className='hiiw section section--dark'>
      <div className='hiiw__arrows-wrapper'>
        <div className='hiiw__arrows'>
          {currentStep !== 1 && (
            <button type='button' className='hiiw__arrow left' onClick={prevStep}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </button>
          )}
          {currentStep !== steps.length && (
            <button type='button' className='hiiw__arrow right' onClick={nextStep}>
              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          )}
        </div>
      </div>

      <div className='hiiw__inside'>
        <div className='boxed'>
          <h2 className='hiiw__title text-center'>{title}</h2>
          <p className='hiiw__essence text-center'>{essence}</p>
        </div>
        <div className={`hiiw__content ${aboveDesktop === false ? '' : 'boxed'}`}>
          <div ref={stepsRef} className='hiiw__steps'>
            {steps.map((step, index) => (
              <button
                type='button'
                key={step.id}
                className={`hiiw__step-title-wrapper ${getClassForStep(step.id)}`}
                id={`step${step.id}`}
                onClick={() => selectStep(step.id)}
              >
                <p className='hiiw__step-index'>{index + 1}</p>
                <h3 className='hiiw__step-name'>{step.title}</h3>
              </button>
            ))}
          </div>
          <div className={`hiiw__step-content-wrapper ${aboveDesktop === false ? 'boxed' : ''}`}>
            {steps.map(({ imgName, description, id }) => (
              <div key={id} className={`hiiw__visibility-controller ${currentStep === id ? 'show' : 'hidden'}`}>
                <div className={`hiiw__step-content `}>
                  <div>
                    <Image filename={imgName} loading='eager' />
                  </div>
                  <div className='hiiw__step-content-part'>
                    {description.map((descriptionItem) => (
                      <div key={descriptionItem.title}>
                        <h3>{descriptionItem.title}</h3>
                        <p>{descriptionItem.content}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowIsItWorking;
