import React, { useState, useRef } from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import useContent from '../../content/useContent';
import Button from '../Button/Button';
import UsageExampleModal from './UsageExampleModal';
import { lockScroll, unlockScroll } from '../../utils/togglePageScroll';
import Icon1 from '../../assets/sklepy.svg';
import Icon2 from '../../assets/stacje.svg';
import Icon3 from '../../assets/ubezpieczenia.svg';

const UsageExamples = () => {
  const modalWrapperRef = useRef(null);
  const content = useContent('usageExamples');
  const { title, usages } = content;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUsageModal, setCurrentUsageModal] = useState(0);

  const openUsageModal = (usageId) => {
    setCurrentUsageModal(usageId);
    setIsModalVisible(true);
    lockScroll();
  };

  const closeUsageModal = () => {
    setIsModalVisible(false);
    unlockScroll();
  };

  const getIcon = (index) => {
    if (index === 0) return <Icon1 />;
    if (index === 1) return <Icon2 />;
    if (index === 2) return <Icon3 />;
  };

  return (
    <>
      <div className='usage-examples section section--light'>
        <div className='usage-examples__inside boxed'>
          <h2>{title}</h2>
          <div className='col-1-1-1'>
            {usages.map(({ usageId, usageTitle, usageTags, usageText, usageBtnText }, index) => (
              <div key={index}>
                <div className='usage-examples__top'>
                  <div className='usage-examples__icon'>{getIcon(index)}</div>
                </div>

                <h3 className='f2'>{usageTitle}</h3>
                <p className='usage-examples__essence'>{usageText}</p>
                <Button onClick={() => openUsageModal(index)} text={usageBtnText} icon={faCaretRight} type={3} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <UsageExampleModal
        usageId={currentUsageModal}
        onClose={closeUsageModal}
        isModalVisible={isModalVisible}
        forwardedRef={modalWrapperRef}
        getIcon={() => getIcon(currentUsageModal)}
      />
    </>
  );
};

export default UsageExamples;
