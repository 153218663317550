import React, { useRef, useEffect, useState } from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ChowWorld from '../../assets/chow-world.svg';
import useWindowSize from '../../hooks/useWindowSize';
import useContent from '../../content/useContent';
import Button from '../Button/Button';
import { scrollToId } from '../../utils/scrollToId';
import useInterval from '../../hooks/useInterval';

const Landing = () => {
  const content = useContent('landing');
  const { title, titleOptions, essence, list, btns } = content;

  const svgRef = useRef(null);
  const [svgHeight, setSvgHeight] = useState(0);

  const { windowHeight, windowWidth, aboveDesktop } = useWindowSize();
  const [stableWindowHeight, setStableWindowHeight] = useState(null);

  useEffect(() => {
    // debug mobile browser top bar toggle
    setStableWindowHeight(windowHeight);
  }, [windowWidth]);

  useEffect(() => {
    const svg = svgRef.current;

    const getIndent = () => {
      const svgStyles = window.getComputedStyle(svg);
      const svgPadding = svgStyles.getPropertyValue('padding-right');
      const svgMargin = svgStyles.getPropertyValue('margin-right');
      const indent = parseInt(svgPadding) + parseInt(svgMargin);
      setSvgHeight(indent);
    };

    getIndent();
    window.addEventListener('resize', getIndent);
    return () => window.removeEventListener('resize', getIndent);
  }, []);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const currentWord = useRef('');
  const [visibleWord, setVisibleWord] = useState(currentWord.current);
  const [isAdding, setIsAdding] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  const addLetter = (letter) => {
    currentWord.current = currentWord.current + letter;
    setVisibleWord(currentWord.current);
  };
  const delLetter = () => {
    currentWord.current = currentWord.current.slice(0, -1);
    setVisibleWord(currentWord.current);
  };

  const delay = isAdding ? 100 : 40;

  useInterval(() => {
    if (isPaused) return;

    if (!isAdding) {
      if (currentWord.current.length === 0) {
        setCurrentWordIndex((prev) => (prev === titleOptions.length - 1 ? 0 : prev + 1));
        setIsAdding(true);
      }
      delLetter();
    } else {
      if (currentWord.current.length === titleOptions[currentWordIndex].length) {
        setIsPaused(true);

        setTimeout(() => {
          setIsAdding(false);
          setIsPaused(false);
        }, 1500);
      }

      const letter = titleOptions[currentWordIndex].slice(currentWord.current.length, currentWord.current.length + 1);
      addLetter(letter);
    }
  }, delay);

  useEffect(() => {
    if (aboveDesktop === null) return;

    if (!aboveDesktop) {
      currentWord.current = titleOptions[0];
      setVisibleWord(currentWord.current);

      setIsAdding(false);
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  }, [aboveDesktop])

  useEffect(() => {
    currentWord.current = '';
    setCurrentWordIndex(0);
    setIsAdding(true);
  }, [titleOptions]);

  return (
    <section className='landing section section--dark'>
      <div
        style={{ minHeight: stableWindowHeight ? `${stableWindowHeight}px` : '100vh' }}
        className='landing__inside boxed'
        ref={svgRef}
      >
        <div className='landing__svg' style={{ transform: `translateX(${svgHeight}px)` }}>
          <ChowWorld />
        </div>

        <div className='landing__content'>
          <h1 className='landing__title'>
            {title}
            <span className='marker'> {visibleWord}</span>
          </h1>
          <p>{essence}</p>
          {aboveDesktop && <ul>{list && list.map((item) => <li key={item}>{item}</li>)}</ul>}
          <div className='landing__btns'>
            {aboveDesktop && (
              <Button
                id='btnLandingGoToAbout'
                text={btns[1].name}
                icon={faCaretRight}
                type={4}
                onClick={() => scrollToId('about')}
              />
            )}
            <Button
              id='btnLandingGoToContact'
              text={btns[0].name}
              icon={faCaretRight}
              type={1}
              onClick={() => scrollToId('contact')}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
