import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import Landing from '../components/Landing/Landing';
import About from '../components/About/About';
import MainClients from '../components/MainClients/MainClients';
import HowIsItWorking from '../components/HowIsItWorking/HowIsItWorking';
import ForWho from '../components/ForWho/ForWho';
import UsageExamples from '../components/UsageExamples/UsageExamples';
import FreeRaport from '../components/FreeRaport/FreeRaport';
import BlogPosts from '../components/BlogPosts/BlogPosts';
import Contact from '../components/Contact/Contact';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Context } from '../store/context';

const IndexPage = () => {
  // const [state] = useContext(Context);

  const context = useContext(Context);
  if (!context) return null;

  const [state, dispatch] = context;

  const { language } = state;

  return (
    <Layout>
      <SEO title='Home' lang={language === 'PL' ? 'PL' : 'ENG'} />
      <Landing />
      <About />
      <MainClients />
      <HowIsItWorking />
      <ForWho />
      <UsageExamples />
      {/* {language === 'PL' && <FreeRaport />} */}
      <BlogPosts />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
