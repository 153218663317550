import React from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useContent from '../../content/useContent';
import Image from '../Image/Image';
import Button from '../Button/Button';

const BlogPosts = () => {
  const content = useContent('blogPosts');
  if (!content) return null;

  const { posts, btnText, title } = content;

  return (
    <section id='articles' className='blog-posts'>
      <div className='blog-posts__inside boxed'>
        <h2>{title}</h2>
        <div className={`${posts.length > 2 ? 'col-1-1-1' : 'col-1-1'}`}>
          {posts.map(({ postImgUrl, postTitle, postExcerpt, postUrl }) => (
            <div key={postTitle} className='blog-posts__post'>
              <div>
                <Image className='blog-posts__img' filename={postImgUrl} />
              </div>
              <h2 className='f2'>{postTitle}</h2>
              <p>{postExcerpt}</p>
              <Link id='btnGoToArticle' to={postUrl} className='btn btn--3 btn--with-icon'>
                <span className='btn__text'>{btnText}</span>
                <FontAwesomeIcon icon={faCaretRight} />
              </Link>

              {/* <Button text={btnText} icon={faCaretRight} type={3} /> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogPosts;
